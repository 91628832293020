export default {
  "IQBRIMS": "_IQBRIMS_o37j7p",
  "IQBRIMS__editor": "_IQBRIMS__editor_o37j7p",
  "IQBRIMS__required": "_IQBRIMS__required_o37j7p",
  "IQBRIMS__loaded": "_IQBRIMS__loaded_o37j7p",
  "IQBRIMS__loading": "_IQBRIMS__loading_o37j7p",
  "IQBRIMS__file_error": "_IQBRIMS__file_error_o37j7p",
  "IQBRIMS__header": "_IQBRIMS__header_o37j7p",
  "IQBRIMS__file_header": "_IQBRIMS__file_header_o37j7p",
  "IQBRIMS__file_body": "_IQBRIMS__file_body_o37j7p",
  "IQBRIMS__file_footer": "_IQBRIMS__file_footer_o37j7p",
  "IQBRIMS__buttons": "_IQBRIMS__buttons_o37j7p",
  "IQBRIMS__overview_disabled": "_IQBRIMS__overview_disabled_o37j7p",
  "IQBRIMS__paper_disabled": "_IQBRIMS__paper_disabled_o37j7p",
  "IQBRIMS__raw_disabled": "_IQBRIMS__raw_disabled_o37j7p",
  "IQBRIMS__checklist_disabled": "_IQBRIMS__checklist_disabled_o37j7p",
  "IQBRIMS__checkbox": "_IQBRIMS__checkbox_o37j7p",
  "IQBRIMS__has_files": "_IQBRIMS__has_files_o37j7p",
  "IQBRIMS__comment": "_IQBRIMS__comment_o37j7p",
  "IQBRIMS__file_notice": "_IQBRIMS__file_notice_o37j7p",
  "IQBRIMS__raw_note": "_IQBRIMS__raw_note_o37j7p"
};
