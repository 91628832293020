export default {
  "jupyter-link": "_jupyter-link_1l082o",
  "jupyterhub-server-menu": "_jupyterhub-server-menu_1l082o",
  "jupyterhub-selector": "_jupyterhub-selector_1l082o",
  "toolbar-button": "_toolbar-button_1l082o",
  "refresh-button": "_refresh-button_1l082o",
  "no-jupyter-servers": "_no-jupyter-servers_1l082o",
  "jupyter-server": "_jupyter-server_1l082o",
  "jupyterhub-server-submenu": "_jupyterhub-server-submenu_1l082o",
  "jupyterhub-servers": "_jupyterhub-servers_1l082o",
  "jupyterhub-user": "_jupyterhub-user_1l082o",
  "max-servers-exceeded": "_max-servers-exceeded_1l082o"
};
