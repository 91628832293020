define("ember-osf-web/helpers/assert", ["exports", "osf-components/helpers/assert"], function (_exports, _assert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _assert.default;
    }
  });
});
