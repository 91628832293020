export default {
  "policy": "_policy_13ojad",
  "icon": "_icon_13ojad",
  "icon-nonprofit": "_icon-nonprofit_13ojad",
  "icon-earth": "_icon-earth_13ojad",
  "icon-play": "_icon-play_13ojad",
  "icon-workflow": "_icon-workflow_13ojad",
  "icon-cloud": "_icon-cloud_13ojad",
  "icon-group": "_icon-group_13ojad",
  "label-green": "_label-green_13ojad",
  "hero-brand": "_hero-brand_13ojad",
  "sign-up-div": "_sign-up-div_13ojad",
  "student-image": "_student-image_13ojad",
  "quote": "_quote_13ojad",
  "main": "_main_13ojad",
  "attrib": "_attrib_13ojad",
  "label-home": "_label-home_13ojad",
  "grey-pullout": "_grey-pullout_13ojad",
  "space-top": "_space-top_13ojad",
  "space-bottom": "_space-bottom_13ojad",
  "integrations": "_integrations_13ojad",
  "container-footer": "_container-footer_13ojad",
  "feature-1": "_feature-1_13ojad",
  "icon-feature": "_icon-feature_13ojad",
  "feature-2": "_feature-2_13ojad",
  "headline": "_headline_13ojad",
  "feature-3": "_feature-3_13ojad",
  "text-right": "_text-right_13ojad",
  "text-left": "_text-left_13ojad",
  "feature-4": "_feature-4_13ojad",
  "feature-5": "_feature-5_13ojad",
  "feature-6": "_feature-6_13ojad",
  "network-bg": "_network-bg_13ojad",
  "copyright": "_copyright_13ojad",
  "student-wrap": "_student-wrap_13ojad",
  "col-md-4": "_col-md-4_13ojad",
  "mob-clear": "_mob-clear_13ojad",
  "goodbye": "_goodbye_13ojad"
};
