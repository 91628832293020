export default {
  "build_console": "_build_console_1or36p",
  "build_console_button": "_build_console_button_1or36p",
  "build_status": "_build_status_1or36p",
  "build_phase": "_build_phase_1or36p",
  "binderhub_selector": "_binderhub_selector_1or36p",
  "build_terminal": "_build_terminal_1or36p",
  "launch_button": "_launch_button_1or36p",
  "build_dropdown": "_build_dropdown_1or36p",
  "build_launch_button": "_build_launch_button_1or36p",
  "build_launch_toggle": "_build_launch_toggle_1or36p",
  "dropdown_menu": "_dropdown_menu_1or36p"
};
