export default {
  "box-shadow-xs": "_box-shadow-xs_w63v5w",
  "box-shadow-sm": "_box-shadow-sm_w63v5w",
  "box-shadow-md": "_box-shadow-md_w63v5w",
  "box-shadow-lg": "_box-shadow-lg_w63v5w",
  "box-shadow-xl": "_box-shadow-xl_w63v5w",
  "logo-spin": "_logo-spin_w63v5w",
  "spin": "_spin_w63v5w",
  "opacity": "_opacity_w63v5w",
  "logo-": "_logo-_w63v5w",
  "osf-box": "_osf-box_w63v5w",
  "box-round": "_box-round_w63v5w",
  "osf-box-lt": "_osf-box-lt_w63v5w",
  "osf-box-dk": "_osf-box-dk_w63v5w",
  "osf-project-navbar": "_osf-project-navbar_w63v5w",
  "navbar-nav": "_navbar-nav_w63v5w",
  "active": "_active_w63v5w",
  "project-title": "_project-title_w63v5w",
  "secondary-navigation": "_secondary-navigation_w63v5w",
  "navbarScope": "_navbarScope_w63v5w",
  "container": "_container_w63v5w",
  "navbar-header": "_navbar-header_w63v5w",
  "navbar-toggle": "_navbar-toggle_w63v5w",
  "collapsed": "_collapsed_w63v5w",
  "navbar-collapse": "_navbar-collapse_w63v5w",
  "collapse": "_collapse_w63v5w",
  "navbar-text": "_navbar-text_w63v5w",
  "in": "_in_w63v5w",
  "collapsing": "_collapsing_w63v5w",
  "nav": "_nav_w63v5w",
  "dropdown": "_dropdown_w63v5w",
  "secondary-nav-dropdown": "_secondary-nav-dropdown_w63v5w",
  "btn-link": "_btn-link_w63v5w",
  "noresize": "_noresize_w63v5w",
  "navbar-donate-button": "_navbar-donate-button_w63v5w",
  "nav-profile-name": "_nav-profile-name_w63v5w",
  "osf-profile-image": "_osf-profile-image_w63v5w",
  "btn-default": "_btn-default_w63v5w",
  "btn": "_btn_w63v5w",
  "form-control": "_form-control_w63v5w",
  "panel": "_panel_w63v5w",
  "panel-heading": "_panel-heading_w63v5w",
  "panel-footer": "_panel-footer_w63v5w",
  "panel-title": "_panel-title_w63v5w",
  "modal-footer": "_modal-footer_w63v5w",
  "alert": "_alert_w63v5w",
  "alert-dismissable": "_alert-dismissable_w63v5w",
  "alert-dismissible": "_alert-dismissible_w63v5w",
  "well": "_well_w63v5w",
  "progress": "_progress_w63v5w",
  "modal-content": "_modal-content_w63v5w",
  "modal-header": "_modal-header_w63v5w",
  "nav-tabs": "_nav-tabs_w63v5w",
  "off": "_off_w63v5w",
  "btn-top-login": "_btn-top-login_w63v5w",
  "btn-top-signup": "_btn-top-signup_w63v5w",
  "navbar-default": "_navbar-default_w63v5w",
  "open": "_open_w63v5w",
  "navbar-brand": "_navbar-brand_w63v5w",
  "navbar-inverse": "_navbar-inverse_w63v5w",
  "navbar": "_navbar_w63v5w",
  "dropdown-menu": "_dropdown-menu_w63v5w",
  "pull-right": "_pull-right_w63v5w",
  "divider": "_divider_w63v5w",
  "disabled": "_disabled_w63v5w",
  "dropdown-menu-right": "_dropdown-menu-right_w63v5w",
  "dropdown-menu-left": "_dropdown-menu-left_w63v5w",
  "dropdown-header": "_dropdown-header_w63v5w",
  "dropdown-backdrop": "_dropdown-backdrop_w63v5w",
  "dropup": "_dropup_w63v5w",
  "caret": "_caret_w63v5w",
  "navbar-fixed-bottom": "_navbar-fixed-bottom_w63v5w",
  "navbar-right": "_navbar-right_w63v5w",
  "menuLogin": "_menuLogin_w63v5w",
  "sign-in": "_sign-in_w63v5w",
  "nav-profile": "_nav-profile_w63v5w",
  "editable": "_editable_w63v5w",
  "editable-click": "_editable-click_w63v5w",
  "progress-bar-": "_progress-bar-_w63v5w",
  "nav-pills": "_nav-pills_w63v5w",
  "osf-nav-wrapper": "_osf-nav-wrapper_w63v5w",
  "search-toggle": "_search-toggle_w63v5w",
  "osf-navbar-logo": "_osf-navbar-logo_w63v5w",
  "primary-nav": "_primary-nav_w63v5w",
  "service-name": "_service-name_w63v5w",
  "current-service": "_current-service_w63v5w",
  "service-dropdown": "_service-dropdown_w63v5w",
  "auth-dropdown": "_auth-dropdown_w63v5w",
  "dropdown-toggle": "_dropdown-toggle_w63v5w",
  "nav-user-dropdown": "_nav-user-dropdown_w63v5w",
  "logoutLink": "_logoutLink_w63v5w",
  "#{$animation-name}": "_#{$animation-name}_w63v5w"
};
