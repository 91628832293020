export default {
  "quickSearch": "_quickSearch_1b28t4",
  "public-projects-box": "_public-projects-box_1b28t4",
  "newAndNoteworthy": "_newAndNoteworthy_1b28t4",
  "bg-web": "_bg-web_1b28t4",
  "institutions-panel": "_institutions-panel_1b28t4",
  "meetings": "_meetings_1b28t4",
  "preprints": "_preprints_1b28t4",
  "btn-banner": "_btn-banner_1b28t4",
  "quick-project": "_quick-project_1b28t4",
  "load-nodes": "_load-nodes_1b28t4",
  "load-nodes-button": "_load-nodes-button_1b28t4",
  "node-col-headers": "_node-col-headers_1b28t4",
  "node-sort-dropdown": "_node-sort-dropdown_1b28t4",
  "prevent-overflow": "_prevent-overflow_1b28t4",
  "quick-search-input": "_quick-search-input_1b28t4",
  "quick-search-col": "_quick-search-col_1b28t4",
  "quick-search-contents": "_quick-search-contents_1b28t4",
  "quick-search-table": "_quick-search-table_1b28t4",
  "prereg-banner": "_prereg-banner_1b28t4",
  "loading-dashboard-item": "_loading-dashboard-item_1b28t4",
  "pointer": "_pointer_1b28t4",
  "osf-project-navbar": "_osf-project-navbar_1b28t4",
  "project-title": "_project-title_1b28t4"
};
