export default {
  "release_group": "_release_group_15b1bk",
  "products_group": "_products_group_15b1bk",
  "release_label": "_release_label_15b1bk",
  "products_label": "_products_label_15b1bk",
  "package_editor": "_package_editor_15b1bk",
  "package_label": "_package_label_15b1bk",
  "package_item": "_package_item_15b1bk",
  "package_add": "_package_add_15b1bk",
  "products_list": "_products_list_15b1bk",
  "edit_release_name": "_edit_release_name_15b1bk",
  "package_item_confirm": "_package_item_confirm_15b1bk",
  "package_item_cancel": "_package_item_cancel_15b1bk",
  "package_help": "_package_help_15b1bk"
};
