export default {
  "container-full": "_container-full_6i2h36",
  "m-t-": "_m-t-_6i2h36",
  "m-b-": "_m-b-_6i2h36",
  "m-l-": "_m-l-_6i2h36",
  "m-r-": "_m-r-_6i2h36",
  "m-": "_m-_6i2h36",
  "m-v-": "_m-v-_6i2h36",
  "m-h-": "_m-h-_6i2h36",
  "p-t-": "_p-t-_6i2h36",
  "p-b-": "_p-b-_6i2h36",
  "p-l-": "_p-l-_6i2h36",
  "p-r-": "_p-r-_6i2h36",
  "p-": "_p-_6i2h36",
  "p-v-": "_p-v-_6i2h36",
  "p-h-": "_p-h-_6i2h36"
};
