export default {
  "Institutions__logo": "_Institutions__logo_1ycdyj",
  "Institutions__table__title": "_Institutions__table__title_1ycdyj",
  "Institutions__header-logo": "_Institutions__header-logo_1ycdyj",
  "Institutions__block": "_Institutions__block_1ycdyj",
  "Institutions__block__link": "_Institutions__block__link_1ycdyj",
  "Institutions__header": "_Institutions__header_1ycdyj",
  "Institutions__footer": "_Institutions__footer_1ycdyj",
  "Institutions__page": "_Institutions__page_1ycdyj",
  "Institutions__browser": "_Institutions__browser_1ycdyj",
  "Institutions__browser_title": "_Institutions__browser_title_1ycdyj",
  "Institutions__table": "_Institutions__table_1ycdyj",
  "Institutions__table__item": "_Institutions__table__item_1ycdyj",
  "Institutions__pagination": "_Institutions__pagination_1ycdyj",
  "Institutions__sorting": "_Institutions__sorting_1ycdyj",
  "Institutions__more": "_Institutions__more_1ycdyj"
};
