define("ember-osf-web/helpers/unique-id", ["exports", "osf-components/helpers/unique-id"], function (_exports, _uniqueId) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uniqueId.default;
    }
  });
  Object.defineProperty(_exports, "uniqueId", {
    enumerable: true,
    get: function get() {
      return _uniqueId.uniqueId;
    }
  });
});
