export default {
  "TitleBar": "_TitleBar_1628nl",
  "TitleBar__title": "_TitleBar__title_1628nl",
  "TitleBar__buttons": "_TitleBar__buttons_1628nl",
  "TitleBar__version-link": "_TitleBar__version-link_1628nl",
  "TitleBar__button-label": "_TitleBar__button-label_1628nl",
  "Main": "_Main_1628nl",
  "EditPanel": "_EditPanel_1628nl",
  "EditPanel__heading": "_EditPanel__heading_1628nl",
  "TagsPanel": "_TagsPanel_1628nl",
  "TagsPanel__heading": "_TagsPanel__heading_1628nl",
  "RevisionsPanel": "_RevisionsPanel_1628nl",
  "RevisionsPanel__heading": "_RevisionsPanel__heading_1628nl",
  "RevisionsPanel__table": "_RevisionsPanel__table_1628nl"
};
