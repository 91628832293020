export default {
  "container": "_container_1pp2zj",
  "integrationsHeader": "_integrationsHeader_1pp2zj",
  "integrationsHeading": "_integrationsHeading_1pp2zj",
  "flexContainer": "_flexContainer_1pp2zj",
  "flexRow": "_flexRow_1pp2zj",
  "flexColumn": "_flexColumn_1pp2zj",
  "logoImg": "_logoImg_1pp2zj",
  "logoTall": "_logoTall_1pp2zj",
  "columnHeader": "_columnHeader_1pp2zj",
  "buttonContainer": "_buttonContainer_1pp2zj"
};
