export default {
  "sign-up-container": "_sign-up-container_19kgff",
  "provider": "_provider_19kgff",
  "provider-logo": "_provider-logo_19kgff",
  "osf-service-logo": "_osf-service-logo_19kgff",
  "sign-up-buttons": "_sign-up-buttons_19kgff",
  "sign-up-button": "_sign-up-button_19kgff",
  "sign-up-button__logo": "_sign-up-button__logo_19kgff",
  "sign-up-button__label": "_sign-up-button__label_19kgff",
  "hr-text": "_hr-text_19kgff"
};
