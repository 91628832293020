define("ember-osf-web/helpers/random-text", ["exports", "osf-components/helpers/random-text"], function (_exports, _randomText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _randomText.default;
    }
  });
  Object.defineProperty(_exports, "randomText", {
    enumerable: true,
    get: function get() {
      return _randomText.randomText;
    }
  });
});
