define("ember-osf-web/packages/registration-schema/index", ["exports", "ember-osf-web/packages/registration-schema/get-pages", "ember-osf-web/packages/registration-schema/get-schema-block-group", "ember-osf-web/packages/registration-schema/schema-block", "ember-osf-web/packages/registration-schema/schema-block-group", "ember-osf-web/packages/registration-schema/validations", "ember-osf-web/packages/registration-schema/registration-response", "ember-osf-web/packages/registration-schema/page-manager"], function (_exports, _getPages, _getSchemaBlockGroup, _schemaBlock, _schemaBlockGroup, _validations, _registrationResponse, _pageManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "getPages", {
    enumerable: true,
    get: function get() {
      return _getPages.getPages;
    }
  });
  Object.defineProperty(_exports, "getSchemaBlockGroups", {
    enumerable: true,
    get: function get() {
      return _getSchemaBlockGroup.getSchemaBlockGroups;
    }
  });
  Object.defineProperty(_exports, "SchemaBlock", {
    enumerable: true,
    get: function get() {
      return _schemaBlock.SchemaBlock;
    }
  });
  Object.defineProperty(_exports, "SchemaBlockType", {
    enumerable: true,
    get: function get() {
      return _schemaBlock.SchemaBlockType;
    }
  });
  Object.defineProperty(_exports, "SchemaBlockGroup", {
    enumerable: true,
    get: function get() {
      return _schemaBlockGroup.SchemaBlockGroup;
    }
  });
  Object.defineProperty(_exports, "buildValidation", {
    enumerable: true,
    get: function get() {
      return _validations.buildValidation;
    }
  });
  Object.defineProperty(_exports, "buildMetadataValidations", {
    enumerable: true,
    get: function get() {
      return _validations.buildMetadataValidations;
    }
  });
  Object.defineProperty(_exports, "setupEventForSyncValidation", {
    enumerable: true,
    get: function get() {
      return _validations.setupEventForSyncValidation;
    }
  });
  Object.defineProperty(_exports, "FileReference", {
    enumerable: true,
    get: function get() {
      return _registrationResponse.FileReference;
    }
  });
  Object.defineProperty(_exports, "RegistrationResponse", {
    enumerable: true,
    get: function get() {
      return _registrationResponse.RegistrationResponse;
    }
  });
  Object.defineProperty(_exports, "ResponseValue", {
    enumerable: true,
    get: function get() {
      return _registrationResponse.ResponseValue;
    }
  });
  Object.defineProperty(_exports, "NormalizedResponseValue", {
    enumerable: true,
    get: function get() {
      return _registrationResponse.NormalizedResponseValue;
    }
  });
  Object.defineProperty(_exports, "NormalizedRegistrationResponse", {
    enumerable: true,
    get: function get() {
      return _registrationResponse.NormalizedRegistrationResponse;
    }
  });
  Object.defineProperty(_exports, "PageManager", {
    enumerable: true,
    get: function get() {
      return _pageManager.PageManager;
    }
  });
});
