export default {
  "loading-icon": "_loading-icon_mkik0",
  "section-container": "_section-container_mkik0",
  "section-header": "_section-header_mkik0",
  "primary-email": "_primary-email_mkik0",
  "email-list": "_email-list_mkik0",
  "email-object": "_email-object_mkik0",
  "no-object-message": "_no-object-message_mkik0",
  "email-address": "_email-address_mkik0",
  "options": "_options_mkik0",
  "merge-explanation": "_merge-explanation_mkik0",
  "input-form": "_input-form_mkik0",
  "add-email-button": "_add-email-button_mkik0"
};
